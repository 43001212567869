import { ref, watch } from "@vue/composition-api";

// Notification
import artistRepository from "@/abstraction/repository/artistRepository";
import musicRepository from "@/abstraction/repository/musicRepository";
import videoRepository from "@/abstraction/repository/videoRepository";
import trendingRepository from "@/abstraction/repository/trendingRepository";
const repositoryArtist = new artistRepository();
const repositoryMusic = new musicRepository();
const repositoryVideo = new videoRepository();
const repository = new trendingRepository();
export default function usemoodList() {
    const refplaylistListTable = ref(null);
    const playlists = ref([]);
    const musics = ref([]);
    const videos = ref([]);
    const type = ref("");
    const fromDate = ref("");
    const toDate = ref("");
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    const date = ref(yyyy + "-" + mm + "-" + dd);
    const perPage = ref(100);
    const customPerPage = ref(100);
    const perPageOptions = [10, 25, 50, 100];
    const sortBy = ref("id");
    const currentPage = ref(1);
    const searchQuery = ref("");
    const isSortDirDesc = ref(true);
    const roleFilter = ref(null);
    const planFilter = ref(null);
    const statusFilter = ref(null);
    const pagination = ref({});
    const musicsPagination = ref({});
    const videoPagination = ref({});
    const typeList = ref([
        {
            type: "artist",
            table: undefined,
            pagination: {},
        },
        {
            type: "music",
            table: undefined,
            pagination: {},
        },
        {
            type: "video",
            table: undefined,
            pagination: {},
        },
    ]);
    const chartFromDate = ref("");
    const chartToDate = ref("");
    const refetchData = () => {
        refplaylistListTable.value.refresh();
    };

    watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
        refetchData();
    });

    // Table Handlers
    const tableColumns = [{ key: "rank", sortable: true }, { key: "from-date", sortable: true }, { key: "to-date", sortable: true }, { key: "actions" }];
    const realTableColumns = [{ key: "rank", sortable: true }, { key: "name", sortable: true }, { key: "date", sortable: true }, { key: "view_count", sortable: true }, { key: "actions" }];
    const realMusicTableColumns = [{ key: "rank", sortable: true }, { key: "name", sortable: true }, { key: "artist_name", sortable: true }, { key: "date", sortable: true }, { key: "view_count", sortable: true }, { key: "actions" }];
    const realVideoTableColumns = [{ key: "rank", sortable: true }, { key: "name", sortable: true }, { key: "artist_name", sortable: true }, { key: "date", sortable: true }, { key: "actions" }];
    const customTableColumns = [{ key: "rank", sortable: true }, { key: "name", sortable: true }, { key: "from-date", sortable: true }, { key: "to-date", sortable: true }, { key: "actions" }];
    const customTableColumns2 = [{ key: "rank", sortable: true }, { key: "name", sortable: true }, { key: "artist_name", sortable: true }, { key: "from-date", sortable: true }, { key: "to-date", sortable: true }, { key: "actions" }];

    const storeManualTrends = async (data) => {
        try {
            return await repository.store(data);
        } catch (e) {
            return false;
        }
    };
    const showManualtrends = async (id) => {
        try {
            return await repository.showManualTrend(id);
        } catch (e) {
            return false;
        }
    };

    const updateManualTrends = async (id, data) => {
        try {
            return await repository.updateManualTrends(id, data);
        } catch (e) {
            return false;
        }
    };
    const updateCoefficient = async (data) => {
        try {
            return await repository.updateCoefficient(data);
        } catch (e) {
            return false;
        }
    };
    const fetchCoefficient = async () => {
        return await repository.coefficientIndex();
    };
    const fetchRealChartOptions = async (type, id, from, to) => {
        console.log(type, id, from, to);
        return await repository.chartIndex(type, id, from, date.value);
    };
    //manual trends list
    const fetchManualTrends = async (paginate = { page: 1, itemsPerPage: customPerPage.value }, typeOfList) => {
        type.value = typeOfList;
        const manualTrendsList = await repository.manualIndex({ pagination: paginate }, type.value, fromDate.value, toDate.value);

        // console.log(manualTrendsList);
        // playlists.value = manualTrendsList.data;
        // pagination.value = manualTrendsList.pagination;
        return manualTrendsList;
    };

    //real trends list
    const fetchRealTypes = async (paginate = { page: 1, itemsPerPage: perPage.value }, filters = {}) => {
        const realTypeList = await repository.realIndex({ filters, pagination: paginate }, type.value, date.value);
        return realTypeList;
    };
    //final trends list
    const fetchFinalTrends = async (paginate = { page: 1, itemsPerPage: perPage.value }, filters = {}) => {
        const finalTypeList = await repository.finalIndex({ filters, pagination: paginate }, type.value);
        return finalTypeList;
    };

    const deleteManualtrends = async (id, type) => {
        try {
            const response = await repository.deleteManualTrends(id);
            pagination.value.itemsLength--;
            if (type == "artist") {
                const index = typeList.value[0].table.findIndex((x) => x.id === id);
                typeList.value[0].table.splice(index, 1);
                return response;
            } else if (type == "music") {
                const index = typeList.value[1].table.findIndex((x) => x.id === id);
                typeList.value[1].table.splice(index, 1);
                return response;
            } else if (type == "video") {
                const index = typeList.value[2].table.findIndex((x) => x.id === id);
                typeList.value[2].table.splice(index, 1);
                return response;
            }
        } catch (e) {
            return false;
        }
    };
    const changePageFinalTrends = async (page) => {
        console.log(page);
        const response = await fetchFinalTrends({ pagination: page });
        return response;
    };
    const changeArtistFinalTrends = async (paginate) => {
        console.log(paginate);
        const finalTypeList = await repository.finalIndex({ pagination: paginate }, "artist");
        return finalTypeList;
    };
    const changeMusicFinalTrends = async (paginate) => {
        console.log(paginate);
        const finalTypeList = await repository.finalIndex({ pagination: paginate }, "music");
        return finalTypeList;
    };
    //next final trends
    const fetchNextFinalTrends = async (paginate = { page: 1, itemsPerPage: 100 }, filters = {}) => {
        const nextFinalTypeList = await repository.nextFinalIndex({ filters, pagination: paginate }, type.value);
        return nextFinalTypeList;
    };
    const updateNextFinalTrends = async (data, index) => {
        try {
            return await repository.updateNextFinalTrends(data);
        } catch (e) {
            return false;
        }
    };
    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*

    return {
        tableColumns,
        realTableColumns,
        playlists,
        type,
        fromDate,
        toDate,
        pagination,
        musicsPagination,
        videoPagination,
        perPageOptions,
        perPage,
        customPerPage,
        sortBy,
        currentPage,
        updateManualTrends,
        changeArtistFinalTrends,
        changeMusicFinalTrends,
        fetchManualTrends,
        musics,
        videos,
        deleteManualtrends,
        storeManualTrends,
        showManualtrends,
        typeList,
        fetchCoefficient,
        updateCoefficient,
        fetchRealTypes,
        date,
        fetchRealChartOptions,
        chartFromDate,
        chartToDate,
        fetchFinalTrends,
        changePageFinalTrends,
        realVideoTableColumns,
        realMusicTableColumns,
        customTableColumns2,
        customTableColumns,
        fetchNextFinalTrends,
        updateNextFinalTrends,
    };
}
