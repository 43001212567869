<template>
    <div>
        <!-- Table Container Card -->
        <h4 class="font-weight-bolder">Real Trends List</h4>
        <b-card no-body class="mb-0">
            <b-overlay :show="show" rounded="sm" no-fade>
                <b-tabs pills align="center" class="mt-3" nav-class="nav-pill-danger">
                    <b-tab @click="sortTable" title="Artists" active>
                        <div class="m-2">
                            <!-- Table Top -->
                            <b-row>
                                <!-- Per Page -->
                                <b-col cols="12" md="6" class="d-flex align-items-center justify-content-center mb-1 mb-md-0">
                                    <label>Show</label>
                                    <b-form-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" @change="changePagePlaylist({ page: 1, itemsPerPage: $event }, 'artist')" />
                                    <label>entries</label>
                                </b-col>
                                <b-col cols="12" md="6" class="d-flex align-items-center justify-content-center mb-1 mb-md-0">
                                    <b-row>
                                        <b-col cols="12">
                                            <b-form-datepicker id="datepicker-placeholder" placeholder="Choose Date" local="en" initial-date="2022-06-16" selected-variant="warning" class="mx-1" v-model="fromDate" @input="setDate('artist')" />
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                        </div>

                        <b-table ref="refUserListTable" class="position-relative pb-3" :items="typeList[0].table" :per-page="perPage" responsive :fields="realTableColumns" primary-key="id" show-empty empty-text="No matching records found">
                            <!-- Column: Music -->
                            <template #cell(rank)="data">
                                <span class="font-weight-bolder text-primary">{{ data.item.rank }}</span>
                            </template>
                            <!-- Column: Genre -->
                            <template #cell(date)="data">
                                <div class="text-nowrap">
                                    <span class="align-text-top text-warning">{{ data.item.date }}</span>
                                </div>
                            </template>
                            <!-- Column: Actions -->
                            <template #cell(actions)="data">
                                <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
                                    <template #button-content>
                                        <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                                    </template>
                                    <b-dropdown-item
                                        :to="{
                                            name: 'apps-trending-real-details',
                                            params: { id: data.item.trendable_id, type: 'artist' },
                                        }"
                                    >
                                        <feather-icon icon="FileTextIcon" />
                                        <span class="align-middle ml-50">Details</span>
                                    </b-dropdown-item>
                                </b-dropdown>
                            </template>
                        </b-table>
                        <div class="mx-2 mb-2">
                            <b-row>
                                <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                                    <span class="text-muted">Showing {{ typeList[0].pagination.pageStart }} to {{ typeList[0].pagination.pageStop }} of {{ typeList[0].pagination.itemsLength }} entries</span>
                                </b-col>
                                <!-- Pagination -->
                                <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                                    <b-pagination v-model="currentPage" :total-rows="typeList[0].pagination.itemsLength" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item" @change="changePagePlaylist({ page: $event, itemsPerPage: perPage }, 'artist')">
                                        <template #prev-text>
                                            <feather-icon icon="ChevronLeftIcon" size="18" />
                                        </template>
                                        <template #next-text>
                                            <feather-icon icon="ChevronRightIcon" size="18" />
                                        </template>
                                    </b-pagination>
                                </b-col>
                            </b-row>
                        </div>
                    </b-tab>
                    <b-tab @click="sortTable" title="Musics">
                        <div class="m-2">
                            <b-row>
                                <!-- Per Page -->
                                <b-col cols="12" md="6" class="d-flex align-items-center justify-content-center mb-1 mb-md-0">
                                    <label>Show</label>
                                    <b-form-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" @change="changePagePlaylist({ page: 1, itemsPerPage: $event }, 'music')" />
                                    <label>entries</label>
                                </b-col>
                                <b-col cols="12" md="6" class="d-flex align-items-center justify-content-center mb-1 mb-md-0">
                                    <b-row>
                                        <b-col cols="12">
                                            <b-form-datepicker id="datepicker-placeholder" placeholder="Choose Date" local="en" initial-date="2022-06-16" selected-variant="warning" class="mx-1" v-model="fromDate" @input="setDate('music')" />
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                        </div>

                        <b-table ref="refUserListTable" class="position-relative pb-3" :items="typeList[1].table" :per-page="perPage" responsive :fields="realMusicTableColumns" primary-key="id" show-empty empty-text="No matching records found">
                            <template #cell(rank)="data">
                                <span class="font-weight-bolder text-primary">{{ data.item.rank }}</span>
                            </template>
                            <!-- Column: Genre -->
                            <template #cell(date)="data">
                                <div class="text-nowrap">
                                    <span class="align-text-top text-capitalize text-warning">{{ data.item.date }}</span>
                                </div>
                            </template>
                            <template #cell(artist_name)="data">
                                <div class="text-nowrap">
                                    <span class="align-text-top text-capitalize text-warning">{{ data.item.artist_name }}</span>
                                </div>
                            </template>
                            <!-- Column: Actions -->
                            <template #cell(actions)="data">
                                <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
                                    <template #button-content>
                                        <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                                    </template>
                                    <b-dropdown-item
                                        :to="{
                                            name: 'apps-trending-real-details',
                                            params: { id: data.item.trendable_id, type: 'music' },
                                        }"
                                    >
                                        <feather-icon icon="FileTextIcon" />
                                        <span class="align-middle ml-50">Details</span>
                                    </b-dropdown-item>
                                </b-dropdown>
                            </template>
                        </b-table>
                        <div class="mx-2 mb-2">
                            <b-row>
                                <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                                    <span class="text-muted">Showing {{ typeList[1].pagination.pageStart }} to {{ typeList[1].pagination.pageStop }} of {{ typeList[1].pagination.itemsLength }} entries</span>
                                </b-col>
                                <!-- Pagination -->
                                <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                                    <b-pagination v-model="currentPage" :total-rows="typeList[1].pagination.itemsLength" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item" @change="changePagePlaylist({ page: $event, itemsPerPage: perPage }, 'music')">
                                        <template #prev-text>
                                            <feather-icon icon="ChevronLeftIcon" size="18" />
                                        </template>
                                        <template #next-text>
                                            <feather-icon icon="ChevronRightIcon" size="18" />
                                        </template>
                                    </b-pagination>
                                </b-col>
                            </b-row>
                        </div>
                    </b-tab>
                    <b-tab @click="sortTable" title="Videos">
                        <div class="m-2">
                            <b-row>
                                <!-- Per Page -->
                                <b-col cols="12" md="6" class="d-flex align-items-center justify-content-center mb-1 mb-md-0">
                                    <label>Show</label>
                                    <b-form-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" @change="changePagePlaylist({ page: 1, itemsPerPage: $event }, 'video')" />
                                    <label>entries</label>
                                </b-col>
                                <b-col cols="12" md="6" class="d-flex align-items-center justify-content-center mb-1 mb-md-0">
                                    <b-row>
                                        <b-col cols="12">
                                            <b-form-datepicker id="datepicker-placeholder" placeholder="Choose Date" local="en" initial-date="2022-06-16" selected-variant="warning" class="mx-1" v-model="fromDate" @input="setDate('video')" />
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                        </div>

                        <b-table ref="refUserListTable" class="position-relative pb-3" :items="typeList[2].table" :per-page="perPage" responsive :fields="realMusicTableColumns" primary-key="id" show-empty empty-text="No matching records found">
                            <template #cell(rank)="data">
                                <span class="font-weight-bolder text-primary">{{ data.item.rank }}</span>
                            </template>
                            <!-- Column: Genre -->
                            <template #cell(date)="data">
                                <div class="text-nowrap">
                                    <span class="align-text-top text-capitalize text-warning">{{ data.item.date }}</span>
                                </div>
                            </template>
                            <!-- Column: Actions -->
                            <template #cell(actions)="data">
                                <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
                                    <template #button-content>
                                        <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                                    </template>
                                    <b-dropdown-item
                                        :to="{
                                            name: 'apps-trending-real-details',
                                            params: { id: data.item.trendable_id, type: 'video' },
                                        }"
                                    >
                                        <feather-icon icon="FileTextIcon" />
                                        <span class="align-middle ml-50">Details</span>
                                    </b-dropdown-item>
                                </b-dropdown>
                            </template>
                        </b-table>
                        <div class="mx-2 mb-2">
                            <b-row>
                                <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                                    <span class="text-muted">Showing {{ typeList[2].pagination.pageStart }} to {{ typeList[2].pagination.pageStop }} of {{ typeList[2].pagination.itemsLength }} entries</span>
                                </b-col>
                                <!-- Pagination -->
                                <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                                    <b-pagination v-model="currentPage" :total-rows="typeList[2].pagination.itemsLength" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item" @change="changePagePlaylist({ page: $event, itemsPerPage: perPage }, 'video')">
                                        <template #prev-text>
                                            <feather-icon icon="ChevronLeftIcon" size="18" />
                                        </template>
                                        <template #next-text>
                                            <feather-icon icon="ChevronRightIcon" size="18" />
                                        </template>
                                    </b-pagination>
                                </b-col>
                            </b-row>
                        </div>
                    </b-tab>
                </b-tabs>
            </b-overlay>
        </b-card>
        <AddMusicToPlaylistList />
    </div>
</template>

<script>
import AddMusicToPlaylistList from "@/views/apps/play-list/AddMusicToPlayList.vue";
import { BTabs, BTab, BCard, BRow, BCol, BFormInput, BFormSelect, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, BFormDatepicker, BOverlay } from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import PlayListListFilters from "./PlayListListFilters.vue";
import useTrendingList from "./useTrendingList";
import userStoreModule from "../user/userStoreModule";
import PlayListRepository from "@/abstraction/repository/playListRepository";

const playlistRepository = new PlayListRepository();
export default {
    components: {
        BOverlay,
        PlayListListFilters,
        AddMusicToPlaylistList,
        BCard,
        BRow,
        BCol,
        BFormInput,
        BFormSelect,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        BTabs,
        BTab,
        vSelect,
        BFormDatepicker,
    },
    setup() {
        const {
            perPageOptions,
            playlists,
            musics,
            videos,
            type,
            fromDate,
            toDate,
            pagination,
            musicsPagination,
            videoPagination,
            perPage,
            sortBy,
            tableColumns,
            realTableColumns,
            realVideoTableColumns,
            realMusicTableColumns,
            currentPage,
            fetchPlaylist,
            fetchRealTypes,
            typeList,
            // Extra Filters
            roleFilter,
            planFilter,
            statusFilter,
            date,
        } = useTrendingList();
        const choosedTab = ref("artists");
        const artistPerPage = ref(10);
        const musicPerPage = ref(10);
        const videoPerPage = ref(10);
        const show = ref(false);
        const sortTable = async () => {
            // show.value = true;
            fromDate.value = "";
            currentPage.value = 1;
            for (let i = 0; i < typeList.value.length; i++) {
                type.value = typeList.value[i].type;
                await fetchRealTypes().then((res) => {
                    console.log(res);
                    typeList.value[i].table = res.data;
                    typeList.value[i].pagination = res.pagination;
                });
                if ((i === 2)) {
                    show.value == false;
                }
            }
            // show.value = false;
        };
        onMounted(() => {
            for (let i = 0; i < typeList.value.length; i++) {
                type.value = typeList.value[i].type;
                fetchRealTypes().then((res) => {
                    console.log(res);
                    typeList.value[i].table = res.data;
                    typeList.value[i].pagination = res.pagination;
                });
            }
        });
        const changePagePlaylist = async (paginate, typeName) => {
            console.log(paginate);
            // if (typeName == "artist") {
            //     perPage.value = artistPerPage.value;
            // }
            type.value = typeName;
            await fetchRealTypes(paginate).then((res) => {
                console.log(res);
                if (typeName == "artist") {
                    typeList.value[0].table = res.data;
                    typeList.value[0].pagination = res.pagination;
                } else if (typeName == "music") {
                    typeList.value[1].table = res.data;
                    typeList.value[1].pagination = res.pagination;
                } else if (typeName == "video") {
                    typeList.value[2].table = res.data;
                    typeList.value[2].pagination = res.pagination;
                }
            });
        };

        const updateSort = async (id) => {
            await playlistRepository.updateSort(id);
            await fetchRealTypes({ page: currentPage, perPage: perPage });
        };
        const setDate = (typeName) => {
            date.value = fromDate.value;
            type.value = typeName;
            fetchRealTypes().then((res) => {
                console.log(res);
                if (typeName == "artist") {
                    typeList.value[0].table = res.data;
                    typeList.value[0].pagination = res.pagination;
                } else if (typeName == "music") {
                    typeList.value[1].table = res.data;
                    typeList.value[1].pagination = res.pagination;
                } else if (typeName == "video") {
                    typeList.value[2].table = res.data;
                    typeList.value[2].pagination = res.pagination;
                }
            });
        };
        return {
            artistPerPage,
            musicPerPage,
            videoPerPage,
            playlists,
            musics,
            videos,
            type,
            pagination,
            videoPagination,
            // Filter
            avatarText,
            perPageOptions,
            changePagePlaylist,
            fetchPlaylist,
            perPage,
            currentPage,
            sortBy,
            tableColumns,
            realTableColumns,
            // Extra Filters
            roleFilter,
            planFilter,
            statusFilter,
            updateSort,
            choosedTab,
            typeList,
            setDate,
            toDate,
            fromDate,
            sortTable,
            show,
            realVideoTableColumns,
            realMusicTableColumns,
        };
    },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

a {
    color: $red;
}
</style>
