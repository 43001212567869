import axios from "axios";
import url from "../url";
import { getArray, setQuery, getJson, setData, getCoefficientJson, setCoefficient } from "../resources/trendingResource";

export default class PlayListRepository {
    constructor(config = {}) {
        axios.defaults.errorHandle = config.errorHandle;
    }

    async manualIndex(data, type, from, to) {
        if (from !== undefined && to !== undefined) {
            const params = setQuery(data);
            const response = await axios.get(`api/admin-manual-trends/${type}/${from}/${to}`, { params });
            if (response.status === 200) {
                return getArray(response.data);
            }
        } else if (from !== undefined) {
            const params = setQuery(data);
            const response = await axios.get(`api/admin-manual-trends/${type}/${from}`, { params });
            if (response.status === 200) {
                return getArray(response.data);
            }
        } else {
            const params = setQuery(data);
            const response = await axios.get(`api/admin-manual-trends/${type}`, { params });
            if (response.status === 200) {
                return getArray(response.data);
            }
        }
    }
    async realIndex(data, type, date) {
        const params = setQuery(data);
        const response = await axios.get(`api/admin-real-trends/${type}/${date}`, { params });
        if (response.status === 200) {
            return getArray(response.data);
        }
    }
    async finalIndex(data, type) {
        const params = setQuery(data);
        const response = await axios.get(`api/admin-index-final-trends/${type}`, { params });
        console.log(response);
        if (response.status === 200) {
            return getArray(response.data);
        }
    }
    async nextFinalIndex(data, type) {
        const params = setQuery(data);
        const response = await axios.get(`api/admin-next-final-trends/${type}`, { params });
        if (response.status === 200) {
            return response.data;
        }
    }
    async showManualTrend(trendId) {
        const response = await axios.get(url("showManualTrends", { id: trendId }));
        if (response.status === 200) {
            return response.data;
        }
    }
    async coefficientIndex(trendId) {
        const response = await axios.get(url("coefficientIndex"));
        if (response.status === 200) {
            return getCoefficientJson(response.data.data);
        }
    }
    async chartIndex(type, id, from, to) {
        if (from === "") {
            const response = await axios.get(`api/admin-index-play-counts/${type}/${id}/ /${to}`);
            if (response.status === 200) {
                return response.data;
            }
        } else {
            const response = await axios.get(`api/admin-index-play-counts/${type}/${id}/${from}/${to}`);
            if (response.status === 200) {
                return response.data;
            }
        }
    }
    // async show(PlayListId) {
    //     const response = await axios.get(url("showPlaylist", { playlist: PlayListId }));
    //     if (response.status === 200) {
    //         return getJson(response.data.data);
    //     }
    // }

    async store(data) {
        const json = setData(data);
        const response = await axios.post(url("storeManulaTrends"), json);
        console.log(response.status);
        if (response.status === 200) {
            return true;
        } else {
            return false;
        }
    }
    async updateNextFinalTrends(data) {
        const response = await axios.post(url("updateNextFinalTrend"), data);
        if (response.status === 200) {
            return true;
        } else {
            return false;
        }
    }
    async updateManualTrends(id, data) {
        const json = setData(data);
        const response = await axios.put(url("updateManualTrends", { id: id }), json);
        if (response.status === 200) {
            return response.data.data;
        }
    }
    async updateCoefficient(data) {
        const json = setCoefficient(data);
        const response = await axios.put(url("updateCoefficient"), json);
        if (response.status === 200) {
            return response.data.data;
        } else {
            return false;
        }
    }
    async deleteManualTrends(id) {
        const response = await axios.delete(url("deleteManulaTrends", { id: id }));
        if (response.status === 200) {
            return true;
        }
    }
}
