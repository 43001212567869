import { SetPagination, SetQueriesObject } from "@/utils/setQueriesObject";

export const getJson = (data) => ({
    type: data.type,
    id: data.id,
    rank: data.rank,
    from_date: data.from_date,
    to_date: data.to_date,
    date: data.date,
    trendable_id: data.trendable_id,
    name: data.trend_detail.name,
    artist_name: data.trend_detail.artist ? data.trend_detail.artist.name : "",
    view_count: data.last_n_days_views,
});
export const getCoefficientJson = (data) => ({
    view_factor: data.view_factor,
    n_trends: data.n_trends,
    n_days: data.n_days,
    max_manual_days: data.max_manual_days,
});
export const getArray = ({ data, meta }) => {
    const pagination = SetPagination(meta);
    data = data.map((playList) => getJson(playList));
    return { data, pagination };
};
export const setData = (data) => ({
    type: data.type,
    id: data.id,
    rank: data.rank,
    from_date: data.from_date,
    to_date: data.to_date,
});
export const setCoefficient = (data) => ({
    view_factor: data.view_factor,
    n_trends: data.n_trends,
    n_days: data.n_days,
    max_manual_days: data.max_manual_days,
});
export const setQuery = (data) => SetQueriesObject(data);
